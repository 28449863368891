import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as uuid from 'uuid';
import analysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import Urls from '../../constants/Urls';
import { getDateByInterval, getHeaderRow } from '../../explore/aiInsights/utils';
import formatValue from '../../lib/formatValue';
import { AsyncThunkConfig } from '../../store/store';
import { setConversationId } from './reducer';

function getAiInsightsContext({ chart }: { chart: any }) {
	const {
		data,
		breakdownSegment,
		employeeAttribute,
		analysisType,
		columnProps,
		interval,
		mainSegments,
		attributeName,
		date,
		summary,
	} = chart;

	const isSubtable = (breakdownSegment && breakdownSegment.values.length > 1) || employeeAttribute;

	const context: any[] = [];

	if (isSubtable) {
		switch (analysisType) {
			case analysisTypes.Index: {
				const header = getHeaderRow({
					baseHeader: ['Date', attributeName],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any,
				});

				context.push(header);

				data.forEach((d: any) => {
					mainSegments[0].values.forEach((segment: string) => {
						const row = [];
						const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

						row.push(getDateByInterval(date, interval));
						row.push(d.label);
						row.push(segment);

						columnProps?.forEach((c: any) => {
							const source = c.source;
							const unit = c.unit;

							const value = d[`${segmentKey}-${source}`];

							row.push(
								formatValue({
									unit,
									value,
								})
							);
						});

						context.push(row);
					});
				});

				mainSegments[0].values.forEach((segment: string) => {
					const row = [];
					const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

					row.push(getDateByInterval(date, 'Day'));
					row.push('Average');
					row.push(segment);

					columnProps?.forEach((c: any) => {
						const source = c.source;
						const unit = c.unit;

						const avgValue = summary[segmentKey][source];

						row.push(
							formatValue({
								unit,
								value: avgValue,
							})
						);
					});

					context.push(row);
				});

				return context;
			}
			case analysisTypes.Percentage: {
				const header = getHeaderRow({
					baseHeader: [
						'Date',
						'Interval',
						chart.attributeName,
						mainSegments.map((s: any) => s.name).join(' & ') || '',
					],
					columnProps: columnProps as any[],
				});

				context.push(header);

				Object.keys(chart.summary)?.forEach((key: string) => {
					data?.forEach((d: any) => {
						const row = [];
						const formattedSegment = key
							.split('_')
							.map((s: string) => s.split('-').join(' '))
							.join(' ');
						row.push(getDateByInterval(chart.date, interval as string));
						row.push(interval as string);
						row.push(d.label);
						row.push(formattedSegment);
						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									value: d[key + '-' + c.source],
									unit: c.unit,
								})
							);
						});

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes.LinearRegression: {
				const header = getHeaderRow({
					baseHeader: ['Date', chart.attributeName],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any,
				});

				context.push(header);

				data.forEach((d: any) => {
					mainSegments[0].values.forEach((segment: string) => {
						const row = [];
						const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

						row.push(getDateByInterval(chart.date, interval));
						row.push(d.label);
						row.push(segment);

						columnProps?.forEach((c: any) => {
							const source = c.source;
							const unit = c.unit;

							const value = d[`${segmentKey}-${source}`];

							if (!value) {
								row.push(0);
							} else {
								row.push(
									formatValue({
										unit,
										value,
									})
								);
							}
						});

						context.push(row);
					});
				});

				mainSegments[0].values.forEach((segment: string) => {
					const row = [];
					const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

					row.push(getDateByInterval(chart.date, interval));
					row.push('Average');
					row.push(segment);

					columnProps?.forEach((c: any) => {
						const source = c.source;
						const unit = c.unit;

						const totalValue = summary[segmentKey][source];

						row.push(
							formatValue({
								unit,
								value: totalValue,
							})
						);
					});

					context.push(row);
				});

				return context;
			}
			default:
				return;
		}
	} else {
		switch (analysisType) {
			case analysisTypes.Index: {
				const header = getHeaderRow({
					baseHeader: ['Time period', 'Interval'],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any,
					additionalHeaders: [data[0]?.label],
				});
				context.push(header);

				data.slice(1, data.length).forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									unit: c.unit,
									value: s[c.source],
								})
							);
						});

						row.push('1x');

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes.Arithmetic: {
				const header = getHeaderRow({
					baseHeader: ['Date', 'Analysises'],
					additionalHeaders: data.map((d: any) => d.text),
				});

				context.push(header);

				const subAnalysises = data[0]?.pointInTime.subAnalyses.map((s: any) => s.label);

				subAnalysises?.forEach((subAnalysis: string) => {
					const row = [];
					const date = getDateByInterval(data[0]?.pointInTime.date, 'Day');

					row.push(date);
					row.push(subAnalysis);

					data.forEach((d: any) => {
						const currentSubAnalysis = d.pointInTime.subAnalyses.find((s: any) => s.label === subAnalysis);
						row.push(
							formatValue({
								value: currentSubAnalysis.value,
								unit: currentSubAnalysis.unit,
							})
						);
					});

					context.push(row);
				});

				return context;
			}
			case analysisTypes.Percentage: {
				const header = getHeaderRow({
					baseHeader: ['Time period', 'Interval'],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any,
				});
				context.push(header);

				data.forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									value: s[c.source],
									unit: c.unit,
								})
							);
						});

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes.LinearRegression: {
				const header = getHeaderRow({
					baseHeader: ['Time period', 'Interval'],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any[],
					additionalHeaders: [data[0]?.label],
				});
				context.push(header);

				data.slice(1, data.length).forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									value: s[c.source],
									unit: c.unit,
								})
							);
						});

						row.push('0%');

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes['Linear Regression']: {
				return;
			}
			default:
				return;
		}
	}
}

export const generateAiInsights = createAsyncThunk<any, void, AsyncThunkConfig>(
	'aiInsights/generate',
	async (_, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const { uid } = getState().auth.userInfo;
		const { reportIds } = getState().dashboard.aiInsights;

		if (!reportIds || !reportIds?.length) {
			throw new Error('No reports selected, please select at least one.');
		}

		const reports: any[] = [];

		reportIds?.forEach((reportId: string) => {
			const reportData = getState().reports[reportId];
			const dashboardReportData = getState().dashboard.view?.reports?.find((r: any) => r.reportId === reportId);

			if (!dashboardReportData) return;

			const reportAiContext = getAiInsightsContext({
				chart: reportData,
			});

			const reportContext = {
				title: dashboardReportData?.name || '',
				interval: reportData.interval,
				analysis_type: reportData?.analysisType,
				data: JSON.stringify(reportAiContext),
				user_context: '',
			};

			reports.push(reportContext);
		});

		const conversation_id = uuid.v4();

		dispatch(setConversationId(conversation_id));

		const response: any = await axios.post(`${Urls.AiInsightsApi}insights/invoke`, {
			input: {
				model: 'gemini_pro_15',
				question: '',
				context: reports,
			},
			config: {
				configurable: {
					conversation_id,
					enterprise_id: enterpriseId,
					user_id: uid,
				},
			},
		});

		return {
			insights: response?.data?.output,
		};
	}
);
