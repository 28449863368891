import produce from 'immer';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useRef, useState } from 'react';

import LottieAnimation from '../../common/LottieAnimation';
import { SortCriteria } from '../../common/SortableHead';
import Table from '../../common/Table';
import useInteractiveChart from '../../common/useInteractiveChart';
import AnalysisTypes from '../../constants/AnalysisTypes';
import GridConstants from '../../constants/GridConstants';
import cn from '../../lib/cn';
import getChartData from '../../lib/getChartData';
import getPeriod from '../../lib/getPeriod';
import Loader from '../../lottie/graph-loader.json';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { navigate } from './actions';

const defaultSortCriteria = {
	columnName: 'text',
	ascending: true,
};

function TableChart() {
	const dispatch = useAppDispatch();
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [sortCriteria, setSortCriteria] = useState<SortCriteria>(defaultSortCriteria);

	const interactiveChart = useInteractiveChart({
		lockedSelection: false,
		mode: 'explore',
	});

	const { interval } = useAppSelector(state => state.explore.filter);
	const {
		data,
		metricOverlay,
		generated,
		generatePending,
		generateError,
		generateInvalidVariation,
		generateNoData,
		analysisType,
		dates,
		currentIndex,
		lifecycle,
		columnProps,
	} = useAppSelector(state => getChartData(state));

	const overlayColumnProps = metricOverlay?.columnProps ?? [];
	const overlayData = metricOverlay?.data ?? [];
	const overlayAnalysisType = metricOverlay?.analysisType;

	const [tableData, setTableData] = useState(data);

	const [tableOverlayData, setTableOverlayData] = useState(overlayData);
	const chartComp = useRef<any>();

	useEffect(() => {
		if (chartComp.current && generated && !generatePending) {
			setWidth(chartComp.current.offsetWidth);
			setHeight(chartComp.current.offsetHeight + 16);
			const handleResize = debounce(handleResizeDebounced, 100);
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}
	}, [tableData]);

	useEffect(() => {
		let processedTableData: any = produce(data, (draftState: any) => {
			draftState.forEach((dataItem: any) => {
				dataItem.series.forEach((seriesItem: any) => {
					const { date, ...fields } = seriesItem;
					const label = getPeriod(date, interval.toLowerCase());
					const prefix = label?.toLowerCase().replace(/ /g, '-');
					Object.keys(fields).forEach(field => {
						dataItem[prefix + '-' + field] = fields[field];
					});
				});
			});
		});
		let removedItems;
		if (analysisType === AnalysisTypes.Index || analysisType === AnalysisTypes.LinearRegression) {
			const index = processedTableData.findIndex((d: any) => d.isEqualityIndex || d.isAttritionIndex);
			if (index !== -1) {
				processedTableData = processedTableData.slice();
				removedItems = processedTableData.splice(index, 1);
			}
		}
		processedTableData = orderBy(
			processedTableData,
			[sortCriteria.columnName],
			[sortCriteria.ascending ? 'asc' : 'desc']
		);
		if (removedItems) {
			processedTableData.unshift(...removedItems);
		}
		setTableData(processedTableData);
	}, [data, sortCriteria]);
	//
	useEffect(() => {
		if (!!overlayData && overlayData.length > 0) {
			let processedTableData: any = produce(overlayData, (draftState: any) => {
				draftState.forEach((dataItem: any) => {
					dataItem.series.forEach((seriesItem: any) => {
						const { date, ...fields } = seriesItem;
						const label = getPeriod(date, interval.toLowerCase());
						const prefix = label?.toLowerCase().replace(/ /g, '-');
						Object.keys(fields).forEach(field => {
							dataItem[prefix + '-' + field] = fields[field];
						});
					});
				});
			});
			let removedItems;
			if (analysisType === AnalysisTypes.Index || analysisType === AnalysisTypes.LinearRegression) {
				const index = processedTableData.findIndex((d: any) => d.isEqualityIndex || d.isAttritionIndex);
				if (index !== -1) {
					processedTableData = processedTableData.slice();
					removedItems = processedTableData.splice(index, 1);
				}
			}
			processedTableData = orderBy(
				processedTableData,
				[sortCriteria.columnName],
				[sortCriteria.ascending ? 'asc' : 'desc']
			);
			if (removedItems) {
				processedTableData.unshift(...removedItems);
			}
			setTableOverlayData(processedTableData);
		}
	}, [sortCriteria, overlayData]);

	function handleSort(columnName: string) {
		let ascending = true;
		if (columnName === sortCriteria.columnName) {
			ascending = !sortCriteria.ascending;
		}
		setSortCriteria({
			columnName,
			ascending,
		});
	}

	function handleResizeDebounced() {
		if (chartComp.current) {
			setWidth(chartComp.current.offsetWidth);
		}
	}

	function renderChart() {
		return (
			<>
				<Table
					width={width}
					data={tableData}
					dates={dates}
					interval={interval}
					lifecycle={lifecycle}
					analysisType={analysisType}
					currentIndex={currentIndex}
					pending={generatePending}
					tableRowHeight={GridConstants.TableRowHeight}
					options={{ showSinglePeriod: true }}
					navigate={navigate}
					sortCriteria={sortCriteria}
					onSort={handleSort}
					columnProps={columnProps}
					{...interactiveChart}
				/>
				{!!tableOverlayData && overlayAnalysisType && overlayColumnProps && (
					<Table
						width={width}
						data={tableOverlayData}
						dates={dates}
						interval={interval}
						lifecycle={lifecycle}
						analysisType={overlayAnalysisType}
						currentIndex={currentIndex}
						pending={generatePending}
						tableRowHeight={GridConstants.TableRowHeight}
						options={{ showSinglePeriod: true }}
						sortCriteria={sortCriteria}
						onSort={handleSort}
						columnProps={overlayColumnProps}
						navigate={props => dispatch(navigate(props))}
						{...interactiveChart}
					/>
				)}
			</>
		);
	}

	if (!generated || generateError || generateNoData || generateInvalidVariation) {
		return null;
	}

	return (
		<div
			className={cn(
				generatePending ? 'explore-chart explore-chart--table-pending' : 'explore-chart explore-chart--table',
				'relative rounded-[20px] overflow-x-auto hide-scrollbar mx-[2rem] mb-[1.6rem] bg-shade-h3'
			)}
		>
			<div
				className={
					generatePending ? 'explore-chart__table explore-chart__table--pending' : 'explore-chart__table'
				}
				ref={chartComp}
				style={generatePending ? { height } : {}}
			>
				{generatePending ? (
					<LottieAnimation
						autoplay={true}
						loop={true}
						width={60}
						height={60}
						animation={Loader}
					></LottieAnimation>
				) : (
					renderChart()
				)}
			</div>
		</div>
	);
}

export default TableChart;
