import moment from 'moment';

import { Segment } from '../../types';

export function getDateByInterval(date: string | Date, interval: string) {
	// Note: adding or removing 2 days to avoid issue with Javascript Date objects always converting to local time.
	// This gives enough margin.

	let momentDate = moment(date);

	if (date instanceof Date && momentDate.date() === 1) {
		momentDate = momentDate.add(2, 'days');
	} else if (date instanceof Date) {
		momentDate = momentDate.subtract(2, 'days');
	}
	switch (interval) {
		case 'Month':
			return momentDate.format('MMMM YYYY');
		case 'Quarter':
			return momentDate.format('YYYY [Q]Q');
		case 'Year':
			return momentDate.format('YYYY');
		case 'Day':
			return momentDate.format('YYYY-MM-DD');
		default: {
			return date;
		}
	}
}

export function getHeaderRow({
	baseHeader = [],
	mainSegments = [],
	columnProps = [],
	additionalHeaders = [],
	overlayColumnProps = [],
	overlaySegments = [],
}: {
	baseHeader?: string[];
	mainSegments?: Segment[];
	overlaySegments?: Segment[];
	columnProps?: any[];
	overlayColumnProps?: any[];
	additionalHeaders?: string[];
}) {
	const header = [...baseHeader];

	if (mainSegments.length > 0) {
		header.push(mainSegments.map((s: any) => s.name).join(' & ') || '');
	}

	if (overlaySegments.length > 0) {
		header.push(overlaySegments.map((s: any) => s.name).join(' & ') || '');
	}
	columnProps?.forEach((c: any) => {
		header.push(c.header);
	});

	if (overlayColumnProps?.length > 0) {
		overlayColumnProps?.forEach((c: any) => {
			header.push(c.header);
		});
	}

	additionalHeaders?.forEach((c: any) => {
		header.push(c);
	});

	return header;
}
