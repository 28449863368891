import * as Tooltip from '@radix-ui/react-tooltip';
import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Animate } from 'react-move';
import analysisTypes from '../constants/AnalysisTypes';

import AnalysisTypes from '../constants/AnalysisTypes';
import CanvasFonts from '../constants/CanvasFonts';
import { X_AXIS_PADDING } from '../constants/ChartSettings';
import Constants from '../constants/Constants';
import FontFamilies from '../constants/FontFamilies';
import inlineFont from '../constants/InlineFont';
import MixPanel from '../constants/MixPanel';
import {
  ARROW_CIRCLE_RADIUS,
  AXIS_FONT_SIZE,
  barTypes,
  LARGE_TEXT_FONT_SIZE,
  PADDING,
  TEXT_FONT_SIZE,
  Y_SMALL_PADDING,
} from '../editor/chart/constants';
import {
  getCenterY,
  getLeftArrowPoints,
  getLeftCenterX,
  getRightArrowPoints,
  getRightCenterX,
  getYAxisRangeValues,
  getYAxisValues,
} from '../lib/chartUtils';
import cn from '../lib/cn';
import measureText from '../lib/measureText';
import precisionRound from '../lib/precisionRound';
import { track } from '../lib/segment';
import { IndexType, ISegmentData, YAxisType } from '../types';
import { BarData } from './BarChartWrapper';
import ChartTooltip from './ChartTooltip';
import YAxisBar from './YAxisBar';

const AXIS_PADDING = 24;
const MIN_GROUP_SPACING = 48;

const pageSizes = [0, 6, 4, 2, 1];

interface IBarChartProps {
  analysisType?: any;
  dataOverlayProp?: any;
  dataProp?: any;
  employeeCountHidden?: any;
  height?: any;
  hoveredItem?: any;
  indexType?: IndexType;
  lifecycle?: any;
  metricOverlayLifecycle: string;
  onHover?: any;
  onHoverEnd?: any;
  onToggle?: any;
  overlayAnalysisType?: analysisTypes;
  overlayData?: ISegmentData[];
  overlayIndexType: IndexType;
  overlayYAxisType: YAxisType;
  reportId?: any;
  selection?: any[];
  selectXAxisGroup?: any;
  standardizedData: BarData[];
  title?: any;
  width?: any;
  xAxisSelection: any;
  yAxisType: YAxisType;
}

export default function BarChart(props: IBarChartProps) {
  const {
    analysisType,
    dataOverlayProp,
    dataProp,
    employeeCountHidden,
    height = 256,
    hoveredItem,
    indexType,
    lifecycle,
    metricOverlayLifecycle,
    onHover,
    onHoverEnd,
    onToggle,
    overlayAnalysisType,
    overlayData = [],
    overlayIndexType,
    overlayYAxisType,
    reportId,
    selection,
    selectXAxisGroup,
    standardizedData,
    title,
    width = 600,
    xAxisSelection,
    yAxisType,
  } = props;

  const [internalHoverId, setInternalHoverId] = useState<string>('');

  if (!standardizedData?.length) {
    return null;
  }

  // optimize?
  const standardizedData2 = useMemo(() => {
    return standardizedData.map(sd => ({
      ...sd,
      bars: sd.bars.filter((d: any) => !d.isEqualityIndex && !d.isAttritionIndex),
    }));
  }, [standardizedData]);

  const isActive = standardizedData.some(sd => sd.bars.some(d => d.isActive as any as boolean));
  const isNonIndexActive = standardizedData2.some(sd => sd.bars.some(d => d.isActive as any as boolean));
  const isLinearRegressionGeneral =
    analysisType === AnalysisTypes.LinearRegression || overlayAnalysisType === AnalysisTypes.LinearRegression;
  const isIndexed = analysisType === AnalysisTypes.Index || overlayAnalysisType === AnalysisTypes.Index;
  const isIndexType = indexType === IndexType.Indexed || overlayIndexType === IndexType.Indexed;
  const showIndexLine = isActive && (isLinearRegressionGeneral || (isIndexed && isIndexType));

  const isChartActive = isNonIndexActive && (hoveredItem || selection?.length);

  const [barType, setBarType] = useState<string>();
  const [barWidth, setBarWidth] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(0);
  const [startIndex, setStartIndex] = useState<number>(0);
  const firstLoadRef = useRef({ firstLoad: true });

  // optimize?
  useEffect(() => {
    const barCount = standardizedData2[0]?.bars?.length;
    const availableWidth = width - 5 * PADDING;
    let configFound = false;
    const longestFormattedXValue = standardizedData2.reduce((longest, item) => {
      return item.formattedXValue.length > longest.length ? item.formattedXValue : longest;
    }, '');
    const maxTextSize = measureText(longestFormattedXValue, CanvasFonts.Medium13).width;
    pageSizes.forEach(ps => {
      if (configFound) return;
      if (ps > standardizedData2.length) return;
      barTypes.forEach((bt, j) => {
        if (configFound) return;
        const groupCount = ps === 0 ? standardizedData2.length : ps;
        const maxGroupWidth = Math.max(bt.max * barCount, maxTextSize);
        const minGroupWidth = Math.max(bt.min * barCount, maxTextSize);
        const maxGraphWidth = maxGroupWidth * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
        const minGraphWidth = minGroupWidth * groupCount + (groupCount - 1) * MIN_GROUP_SPACING;
        let barWidth;
        if (j === 0 && AnalysisTypes.LinearRegression === analysisType && maxGraphWidth * 2 < availableWidth) {
          barWidth = (availableWidth - (groupCount - 1) * (MIN_GROUP_SPACING * 2)) / (barCount * groupCount);
          if (groupCount === 1) {
            barWidth = barWidth / 2;
          }

          configFound = true;
        } else if (j === 0 && maxGraphWidth < availableWidth) {
          barWidth = bt.max;
          configFound = true;
        } else if (minGraphWidth <= width && maxGraphWidth > availableWidth) {
          barWidth = (availableWidth - (groupCount - 1) * MIN_GROUP_SPACING) / (barCount * groupCount);
          configFound = true;
        }
        if (configFound) {
          barWidth && setBarWidth(barWidth);
          setBarType(bt.type);
          setPageSize(ps);
        }
      });
    });
  }, [width, standardizedData2]);

  useEffect(() => {
    if (barWidth > 0) {
      firstLoadRef.current.firstLoad = false;
    }
  }, [barWidth]);

  function handleToggle(label: string) {
    track(MixPanel.Events.DashboardReportColumnGraphColumnSegmentClick, {
      'Report Name': title,
    });
    onToggle(label);
  }

  function handleXAxisClick(index: number, e: React.MouseEvent<SVGGElement, MouseEvent>) {
    e.stopPropagation();
    selectXAxisGroup(index);
    track(MixPanel.Events.DashboardReportColumnGraphXAxisClick, {
      'Report Name': title,
    });
  }

  function handlePrevious(e: React.MouseEvent<SVGGElement, MouseEvent>) {
    e.stopPropagation();
    if (startIndex === 0) return;
    setStartIndex(startIndex - 1);
    track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
      'Report Name': title,
    });
  }

  function handleNext(e: React.MouseEvent<SVGGElement, MouseEvent>) {
    e.stopPropagation();
    if (startIndex + pageSize >= standardizedData2.length) return;
    setStartIndex(startIndex + 1);
    track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
      'Report Name': title,
    });
  }

  const paginatedData = useMemo(() => {
    if (pageSize === 0) return standardizedData2;

    return [...standardizedData2].slice(startIndex, startIndex + pageSize);
  }, [standardizedData2, startIndex, pageSize, width]);

  const { xScale, yScale, overlayYScale, yAxisRangeValues, overlayYAxisRangeValues } = useMemo(() => {
    const xScale = scaleBand()
      .domain(paginatedData.map((d: BarData) => d.xValue))
      .range([PADDING * 3, width - PADDING * 3]);

    let minArray: any[] = [];
    let maxArray: any[] = [];

    standardizedData.forEach((data: BarData) => {
      minArray.push(
        min(
          data.bars.filter((d: any) => !d.isOverlay && d.yValue !== Constants.InvalidValue),
          (s: any) => s.yValue
        )
      );
      maxArray.push(
        max(
          data.bars.filter((d: any) => !d.isOverlay && d.yValue !== Constants.InvalidValue),
          (s: any) => s.yValue
        )
      );
    });

    minArray = minArray.filter((d: any) => d !== Constants.InvalidValue);
    maxArray = maxArray.filter((d: any) => d !== Constants.InvalidValue);
    const minValue = Math.floor(min(minArray));
    const maxValue = Math.ceil(max(maxArray));

    let yAxisValues: [number, number] = [0, 0];
    let overlayYAxisValues: [number, number] = [0, 0];

    const overlayMinArray = overlayData.map((d: any) =>
      min(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1))
    );
    const overlayMaxArray = overlayData.map((d: any) =>
      max(d.series, (s: any) => precisionRound(s[dataOverlayProp], 1))
    );
    const overlayMinValue = Math.floor(min(overlayMinArray as any as number[]) as number);
    const overlayMaxValue = Math.ceil(max(overlayMaxArray as any as number[]) as number);

    if (
      analysisType === overlayAnalysisType &&
      ((overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType === yAxisType) ||
        (overlayAnalysisType === AnalysisTypes.Index && overlayIndexType === indexType))
    ) {
      const minOverallValue = min([minValue, overlayMinValue]);
      const maxOverallValue = max([maxValue, overlayMaxValue]);

      yAxisValues = getYAxisValues({
        analysisType,
        indexType,
        lifecycle,
        maxValue: maxOverallValue!,
        minValue: minOverallValue!,
        yAxisType,
      });

      overlayYAxisValues = getYAxisValues({
        analysisType: overlayAnalysisType,
        indexType: overlayIndexType,
        lifecycle: metricOverlayLifecycle,
        maxValue: maxOverallValue!,
        minValue: minOverallValue!,
        yAxisType: overlayYAxisType,
      });
    } else {
      yAxisValues = getYAxisValues({
        analysisType,
        indexType,
        lifecycle,
        maxValue,
        minValue,
        yAxisType,
      });

      overlayYAxisValues = getYAxisValues({
        analysisType: overlayAnalysisType,
        indexType: overlayIndexType,
        lifecycle: metricOverlayLifecycle,
        maxValue: overlayMaxValue,
        minValue: overlayMinValue,
        yAxisType: overlayYAxisType,
      });
    }

    const yAxisRangeValues = getYAxisRangeValues(yAxisValues, yAxisType, indexType);

    const range = [height - PADDING - AXIS_PADDING, barType === 'small' ? Y_SMALL_PADDING : PADDING];

    const yScale = scaleLinear().domain(yAxisValues).range(range);

    const overlayYAxisRangeValues = getYAxisRangeValues(overlayYAxisValues, overlayYAxisType, overlayIndexType);

    const overlayYScale = scaleLinear().domain(overlayYAxisValues).range(range);

    return { xScale, yScale, overlayYScale, yAxisRangeValues, overlayYAxisRangeValues };
  }, [
    barType,
    dataOverlayProp,
    dataProp,
    height,
    metricOverlayLifecycle,
    overlayAnalysisType,
    overlayData,
    overlayIndexType,
    overlayYAxisType,
    paginatedData,
    width,
    standardizedData,
  ]);

  if (barWidth === 0 || height === 0) {
    return null;
  }

  const getYScale = (scale: number) =>
    analysisType === AnalysisTypes.LinearRegression || analysisType === AnalysisTypes.Index
      ? yScale(scale)
      : overlayYScale(scale);

  const getYScaleByOverlay = (isOverlay: boolean, scale: number) =>
    isOverlay ? overlayYScale(scale) : yScale(scale);
  const getIsLinearRegression = (isOverlay: boolean) =>
    isOverlay
      ? overlayAnalysisType === AnalysisTypes.LinearRegression
      : analysisType === AnalysisTypes.LinearRegression;

  return (
    <Tooltip.Provider>
      <svg className="barchart" data-export-type="barchart" width={width} height={height} id={reportId}>
        <defs>
          <style type="text/css">{inlineFont}</style>
        </defs>
        {isLinearRegressionGeneral && (
          <rect
            className="barchart__linear-regression-background"
            x={0}
            y={getYScale(0)}
            width={width}
            height={height - getYScale(0)}
          />
        )}
        {isIndexed && isIndexType && (
          <rect
            className="barchart__index-background"
            x={0}
            y={getYScale(1)}
            width={width}
            height={height - getYScale(1)}
            fill="#1f1f21"
          />
        )}
        {paginatedData?.map((groupData: BarData, i: number) => (
          <g key={groupData.xValue.toString()}>
            {groupData?.bars?.map((bar: BarData['bars'][0], j: number) => {
              const groupWidth = barWidth * groupData.bars.length;
              const x = xScale(groupData.xValue)! + (xScale.bandwidth() - groupWidth) / 2 + j * barWidth;
              const isValueNegative = bar.yValue < 0;
              // revert to Zero if invalid value
              const isValueZero = bar.yValue === 0 || bar.yValue === Constants.InvalidValue;
              const barValue = precisionRound(bar.yValue, 1);
              const isLinearRegression = getIsLinearRegression(bar.isOverlay);
              const y = isValueZero
                ? getYScaleByOverlay(bar.isOverlay, 0) - 1
                : isLinearRegression
                  ? isValueNegative
                    ? getYScaleByOverlay(bar.isOverlay, 0)
                    : getYScaleByOverlay(bar.isOverlay, barValue)
                  : getYScaleByOverlay(bar.isOverlay, barValue);

              const barHeight = isValueZero
                ? 1
                : isLinearRegression
                  ? isValueNegative
                    ? getYScaleByOverlay(bar.isOverlay, barValue) - getYScaleByOverlay(bar.isOverlay, 0)
                    : getYScaleByOverlay(bar.isOverlay, 0) - getYScaleByOverlay(bar.isOverlay, barValue)
                  : height - PADDING - AXIS_PADDING - y;

              const isActive = hoveredItem === bar.label;
              const isSelected = selection?.includes(bar.label);
              const barOpacity =
                bar.yValue === Constants.InvalidValue
                  ? 0
                  : isChartActive && !(isActive || isSelected)
                    ? 0.2
                    : 1;
              let formattedText, xText, yText, translateX;
              let showText = false;
              if (barType !== 'small' || isActive || isSelected) {
                showText = true;
                const isCount = bar.isOverlay
                  ? overlayYAxisType === YAxisType.Count
                  : yAxisType === YAxisType.Count;
                formattedText = isCount && employeeCountHidden ? '' : bar.formattedYValue;
                xText = isLinearRegression && isValueNegative ? x + barWidth - 2 : x + 2;
                yText =
                  getYScaleByOverlay(bar.isOverlay, barValue) +
                  (isLinearRegression && isValueNegative ? 12 : -4);
                translateX = (barWidth + TEXT_FONT_SIZE) / 2 - 4;
              }

              return (
                <Fragment>
                  <Tooltip.Root delayDuration={0} open={internalHoverId === bar.internal_id}>
                    <Tooltip.Trigger asChild>
                      <g>
                        <rect
                          key={`${i}-${j}`}
                          x={x}
                          y={y}
                          width={barWidth - 2}
                          height={barHeight}
                          fill={bar.color}
                          opacity={barOpacity}
                          className={cn('transition-all duration-[75ms]')}
                        />

                        <rect
                          data-label="tooltip-trigger"
                          width={barWidth - 2}
                          x={x}
                          y={y}
                          height={barHeight}
                          fill="transparent"
                          cursor="pointer"
                          onMouseEnter={() => {
                            onHover(bar.label);
                            setInternalHoverId(bar.internal_id);
                          }}
                          onMouseLeave={() => {
                            onHoverEnd();
                            setInternalHoverId('');
                          }}
                          onClick={() => handleToggle(bar.label)}
                        />
                      </g>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content side="bottom" sideOffset={16}>
                        <ChartTooltip {...bar.tooltip} />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                  {showText && (
                    <text
                      width={barWidth}
                      x={xText}
                      y={yText}
                      textAnchor={isLinearRegression && isValueNegative ? 'end' : 'start'}
                      height={barHeight}
                      transform={
                        barType === 'small'
                          ? `rotate(-90, ${xText}, ${yText}) translate(0 ${translateX})`
                          : ''
                      }
                      style={{
                        fontFamily: FontFamilies.Regular,
                        fontSize: barType === 'large' ? LARGE_TEXT_FONT_SIZE : TEXT_FONT_SIZE,
                      }}
                      className={cn('transition-all duration-[75ms]')}
                      fill="var(--color-ui-100)"
                      opacity={isChartActive && !(isActive || isSelected) ? 0 : barOpacity}
                      cursor="pointer"
                      onMouseEnter={() => {
                        onHover(bar.label);
                        setInternalHoverId(bar.internal_id);
                      }}
                      onMouseLeave={() => {
                        onHoverEnd();
                        setInternalHoverId('');
                      }}
                      onClick={() => handleToggle(bar.label)}
                    >
                      {formattedText}
                    </text>
                  )}
                </Fragment>
              );
            })}
          </g>
        ))}
        <Animate
          show={showIndexLine}
          start={() => ({
            opacity: 0,
          })}
          enter={() => ({
            opacity: [1],
            timing: {
              duration: Constants.AnimationDuration,
              ease: Constants.EasingFn,
            },
          })}
          update={() => ({
            opacity: [1],
            timing: {
              duration: Constants.AnimationDuration,
              ease: Constants.EasingFn,
            },
          })}
          leave={() => ({
            opacity: [0],
            timing: {
              duration: Constants.AnimationDuration,
              ease: Constants.EasingFn,
            },
          })}
        >
          {state => {
            const indexText = isLinearRegressionGeneral ? 'Index (0%)' : 'Index (1x)';
            const yIndex = isLinearRegressionGeneral ? getYScale(0) : getYScale(1);
            const xIndex = PADDING + measureText(indexText, CanvasFonts.Medium13).width + 8;

            return (
              <Fragment>
                <text
                  className="barchart__index-text"
                  x={PADDING}
                  y={yIndex + 3}
                  opacity={state.opacity}
                >
                  {indexText}
                </text>
                <line
                  className="barchart__index-line"
                  x1={xIndex}
                  y1={yIndex}
                  x2={width - PADDING}
                  y2={yIndex}
                  opacity={state.opacity}
                />
              </Fragment>
            );
          }}
        </Animate>
        <line
          className="barchart__axis-line"
          x1={PADDING}
          y1={height - AXIS_PADDING - PADDING}
          x2={width - PADDING}
          y2={height - AXIS_PADDING - PADDING}
        />
        <YAxisBar
          analysisType={analysisType}
          indexType={indexType}
          xScale={PADDING}
          yAxisRangeValues={yAxisRangeValues}
          yAxisType={yAxisType}
          yScale={yScale}
        />
        {(analysisType !== overlayAnalysisType ||
          (overlayAnalysisType !== AnalysisTypes.Index && overlayYAxisType !== yAxisType) ||
          (overlayAnalysisType === AnalysisTypes.Index && overlayIndexType !== indexType)) && (
            <YAxisBar
              analysisType={overlayAnalysisType!}
              indexType={overlayIndexType}
              xScale={width - PADDING - X_AXIS_PADDING}
              yAxisRangeValues={overlayYAxisRangeValues}
              yAxisType={
                overlayAnalysisType !== AnalysisTypes.Index ? overlayYAxisType : YAxisType.Percentage
              }
              yScale={overlayYScale}
            />
          )}
        <g className="barchart__x-labels">
          {paginatedData.map((data, index) => {
            const selected = xAxisSelection === data.formattedXValue;

            return (
              <text
                className={cn(
                  'barchart__x-labels__text',
                  selected && 'barchart__x-labels__text--selected'
                )}
                key={data.formattedXValue}
                x={xScale(data.xValue)! + xScale.bandwidth() / 2}
                y={height - PADDING - 0.5 * AXIS_FONT_SIZE}
                textAnchor="middle"
                opacity={selected ? 1 : 0.7}
                onClick={!selected ? e => handleXAxisClick(index, e) : e => e.stopPropagation()}
              >
                {data.formattedXValue}
              </text>
            );
          })}
        </g>
        {pageSize > 0 && (
          <Fragment>
            <polygon
              points={getLeftArrowPoints(pageSize, height, width)}
              fill={startIndex === 0 ? 'var(--color-ui-30)' : 'var(--color-ui-100)'}
            />
            <circle
              cx={getLeftCenterX(pageSize, width)}
              cy={getCenterY(height)}
              r={ARROW_CIRCLE_RADIUS}
              cursor={startIndex === 0 ? 'default' : 'pointer'}
              fill="transparent"
              onClick={handlePrevious}
            />
            <polygon
              points={getRightArrowPoints(pageSize, height, width)}
              fill={
                startIndex + pageSize === standardizedData2.length
                  ? 'var(--color-ui-30)'
                  : 'var(--color-ui-100)'
              }
            />
            <circle
              cx={getRightCenterX(pageSize, width)}
              cy={getCenterY(height)}
              r={ARROW_CIRCLE_RADIUS}
              cursor={startIndex + pageSize === standardizedData2.length ? 'default' : 'pointer'}
              fill="transparent"
              onClick={handleNext}
            />
          </Fragment>
        )}
      </svg>
    </Tooltip.Provider>
  );
}
