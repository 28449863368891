import React from 'react';
import Modal from 'antd/lib/modal';
import Button from './Button';
import InfoHtml from './InfoHtml';
import AnalysisTypes from '../constants/AnalysisTypes';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { closeInfo } from './actions';

function InfoModal() {
	const dispatch = useAppDispatch();
	const { category, analysisType, singleSegment, open } = useAppSelector(state => state.infoModal);

	function getInfoHtml() {
		if (singleSegment) {
			if (category?.toLowerCase().includes('compensation') || analysisType === AnalysisTypes.LinearRegression) {
				return InfoHtml.comp1;
			}
			if (category?.toLowerCase().includes('representation')) {
				return InfoHtml.rep1;
			}
			if (category?.toLowerCase().includes('hiring')) {
				return InfoHtml.hir1;
			}
			if (category?.toLowerCase().includes('attrition') || analysisType === AnalysisTypes.Index) {
				return InfoHtml.attr1;
			}
			if (category?.toLowerCase().includes('retention')) {
				return InfoHtml.ret1;
			}
		} else {
			if (category?.toLowerCase().includes('compensation') || analysisType === AnalysisTypes.LinearRegression) {
				return InfoHtml.comp2;
			}
			if (category?.toLowerCase().includes('representation')) {
				return InfoHtml.rep2;
			}
			if (category?.toLowerCase().includes('hiring')) {
				return InfoHtml.hir2;
			}
			if (category?.toLowerCase().includes('attrition') || analysisType === AnalysisTypes.Index) {
				return InfoHtml.attr2;
			}
			if (category?.toLowerCase().includes('retention')) {
				return InfoHtml.ret2;
			}
		}
	}

	function onCancel() {
		dispatch(closeInfo());
	}

	return (
		<Modal
			open={open}
			width={600}
			footer={null}
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			zIndex={3000}
		>
			<div className="modal modal--delete">
				<div className="modal__header">
					<div className="modal__title">What&apos;s the Math?</div>
				</div>
				<div className="modal__content">
					<div
						dangerouslySetInnerHTML={{
							__html: getInfoHtml() || '',
						}}
					/>
				</div>
				<div className="modal__footer">
					<Button onClick={onCancel}>Close</Button>
				</div>
			</div>
		</Modal>
	);
}

export default InfoModal;
