import { createSlice } from '@reduxjs/toolkit';
import actionTypes from '../../constants/ActionTypes';
import { generateAiInsightsInExplore } from './actions';

interface AiInsightsState {
	insights: string;
	pending?: boolean;
	error?: string;
	loaded: boolean;
	aiInsightsModalOpen: boolean;
	cancelled?: boolean;
}

const initialState: AiInsightsState = {
	insights: '',
	aiInsightsModalOpen: false,
	loaded: false,
};

const aiInsightsSlice = createSlice({
	name: 'aiInsights',
	initialState: initialState,
	reducers: {
		setAiInsightsModelOpen: (state, action) => {
			// reset state on modal close.
			if (!action.payload) {
				state.error = undefined;
				state.loaded = false;
				state.aiInsightsModalOpen = false;
				state.insights = '';
				if (state.pending) {
					state.cancelled = true;
				} else {
					state.cancelled = undefined;
				}
				state.pending = false;
			} else {
				state.aiInsightsModalOpen = true;
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(generateAiInsightsInExplore.pending, (state, _) => {
			state.pending = true;
			state.aiInsightsModalOpen = true;
		});
		builder.addCase(generateAiInsightsInExplore.fulfilled, (state, action) => {
			if (state.cancelled) {
				state.cancelled = undefined;
				return;
			}
			state.pending = false;
			state.loaded = true;
			state.insights = action.payload.insights;
		});
		builder.addCase(generateAiInsightsInExplore.rejected, (state, action) => {
			console.log(action);
			state.error =
				'We apologize, but it seems there is an internal issue preventing us from displaying your AI-generated insights at the moment. Our team is actively working to resolve this. Please try again later. If the issue persists, feel free to contact our support team for assistance.';
			state.pending = false;
			state.loaded = true;
		});
		builder.addCase(actionTypes.ChangeLocation, () => initialState);
	},
});

export const { setAiInsightsModelOpen } = aiInsightsSlice.actions;
export default aiInsightsSlice.reducer;
