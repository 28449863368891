import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as uuid from 'uuid';

import analysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import Urls from '../../constants/Urls';
import { setConversationId } from '../../dashboard/aiInsights/reducer';
import formatValue from '../../lib/formatValue';
import { AsyncThunkConfig } from '../../store/store';
import { getDateByInterval, getHeaderRow } from './utils';

function getAiInsightsContext({
	filter,
	chart,
	subtable,
	isSubtable,
}: {
	filter: any;
	chart: any;
	subtable: any;
	isSubtable: boolean;
}) {
	const { interval, mainSegments } = filter;
	const { analysisType, columnProps, data } = chart;

	const {
		date: subtableDate,
		data: subtableData,
		analysisType: subtableAnalysisType,
		columnProps: subtableColumnProps,
		attributeName,
		summary,
	} = subtable;

	const hasOverlay = chart?.metricOverlay?.data?.length > 0;
	const mergedData = hasOverlay ? [...data, ...chart.metricOverlay.data] : data;

	const context: any[] = [];

	if (isSubtable) {
		switch (subtableAnalysisType) {
			case analysisTypes.Index:
				const header = getHeaderRow({
					baseHeader: ['Date', attributeName],

					mainSegments: mainSegments as any[],
					columnProps: subtableColumnProps as any,
				});

				context.push(header);

				subtableData.forEach((d: any) => {
					mainSegments[0].values.forEach((segment: string) => {
						const row = [];
						const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

						row.push(getDateByInterval(subtableDate, interval));
						row.push(d.label);
						row.push(segment);

						subtableColumnProps?.forEach((c: any) => {
							const source = c.source;
							const unit = c.unit;

							const value = d[`${segmentKey}-${source}`];

							row.push(
								formatValue({
									unit,
									value,
								})
							);
						});

						context.push(row);
					});
				});

				mainSegments[0].values.forEach((segment: string) => {
					const row = [];
					const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

					row.push(getDateByInterval(subtableDate, interval));
					row.push('Average');
					row.push(segment);

					subtableColumnProps?.forEach((c: any) => {
						const source = c.source;
						const unit = c.unit;

						const avgValue = summary[segmentKey][source];

						if (!avgValue) {
							row.push(0);
						} else {
							row.push(
								formatValue({
									unit,
									value: avgValue,
								})
							);
						}
					});

					context.push(row);
				});

				return context;
			case analysisTypes.Percentage: {
				const header = getHeaderRow({
					baseHeader: ['Date', attributeName],
					mainSegments: mainSegments as any[],
					columnProps: subtableColumnProps as any,
				});

				context.push(header);

				subtableData.forEach((d: any) => {
					mainSegments[0].values.forEach((segment: string) => {
						const row = [];
						const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

						row.push(getDateByInterval(subtableDate, interval));
						row.push(d.label);
						row.push(segment);

						subtableColumnProps?.forEach((c: any) => {
							const source = c.source;
							const unit = c.unit;

							const value = d[`${segmentKey}-${source}`];

							if (!value) {
								row.push(0);
							} else {
								row.push(
									formatValue({
										unit,
										value,
									})
								);
							}
						});

						context.push(row);
					});
				});

				mainSegments[0].values.forEach((segment: string) => {
					const row = [];
					const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

					row.push(getDateByInterval(subtableDate, interval));
					row.push('Total');
					row.push(segment);

					subtableColumnProps?.forEach((c: any) => {
						const source = c.source;
						const unit = c.unit;

						const totalValue = summary[segmentKey][source];

						if (!totalValue) {
							row.push(0);
						} else {
							row.push(
								formatValue({
									unit,
									value: totalValue,
								})
							);
						}
					});

					context.push(row);
				});

				return context;
			}
			case analysisTypes.LinearRegression: {
				const header = getHeaderRow({
					baseHeader: ['Date', attributeName],
					mainSegments: mainSegments as any[],
					columnProps: subtableColumnProps as any,
				});

				context.push(header);

				subtableData.forEach((d: any) => {
					mainSegments[0].values.forEach((segment: string) => {
						const row = [];
						const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

						row.push(getDateByInterval(subtableDate, interval));
						row.push(d.label);
						row.push(segment);

						subtableColumnProps?.forEach((c: any) => {
							const source = c.source;
							const unit = c.unit;

							const value = d[`${segmentKey}-${source}`];

							if (!value) {
								row.push(0);
							} else {
								row.push(
									formatValue({
										unit,
										value,
									})
								);
							}
						});

						context.push(row);
					});
				});

				mainSegments[0].values.forEach((segment: string) => {
					const row = [];
					const segmentKey = `${segment.replace(/ /g, '-').toLowerCase()}`;

					row.push(getDateByInterval(subtableDate, interval));
					row.push('Average');
					row.push(segment);

					subtableColumnProps?.forEach((c: any) => {
						const source = c.source;
						const unit = c.unit;

						const totalValue = summary[segmentKey][source];

						row.push(
							formatValue({
								unit,
								value: totalValue,
							})
						);
					});

					context.push(row);
				});

				return context;
			}
			case analysisTypes['Linear Regression']:
				return;

			default:
				return;
		}
	} else {
		switch (analysisType) {
			case analysisTypes.Index: {
				const header = getHeaderRow({
					baseHeader: ['Time period', 'Interval'],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any,
					additionalHeaders: [data[0]?.label],
				});
				context.push(header);

				data.slice(1, data.length).forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									unit: c.unit,
									value: s[c.source],
								})
							);
						});

						row.push('1x');

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes.Arithmetic: {
				const header = getHeaderRow({
					baseHeader: ['Date', 'Analysises'],
					additionalHeaders: data.map((d: any) => d.text),
				});

				context.push(header);

				const subAnalysises = data[0]?.pointInTime.subAnalyses.map((s: any) => s.label);

				subAnalysises?.forEach((subAnalysis: string) => {
					const row = [];
					const date = getDateByInterval(data[0]?.pointInTime.date, 'Day');

					row.push(date);
					row.push(subAnalysis);

					data.forEach((d: any) => {
						const currentSubAnalysis = d.pointInTime.subAnalyses.find((s: any) => s.label === subAnalysis);
						row.push(
							formatValue({
								value: currentSubAnalysis.value,
								unit: currentSubAnalysis.unit,
							})
						);
					});

					context.push(row);
				});

				return context;
			}
			case analysisTypes.Percentage: {
				const header = getHeaderRow({
					baseHeader: ['Time period', 'Interval'],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any,
					overlaySegments: chart?.metricOverlay?.mainSegments || ([] as any[]),
					overlayColumnProps: chart?.metricOverlay?.columnProps || ([] as any),
				});

				context.push(header);

				mergedData.forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									value: s[c.source],
									unit: c.unit,
								})
							);
						});

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes.LinearRegression: {
				const header = getHeaderRow({
					baseHeader: ['Time period', 'Interval'],
					mainSegments: mainSegments as any[],
					columnProps: columnProps as any[],
					additionalHeaders: [data[0]?.label],
				});
				context.push(header);

				data.slice(1, data.length).forEach((d: any) => {
					d.series.forEach((s: any) => {
						const row = [];
						row.push(getDateByInterval(s.date, interval as string));
						row.push(interval);
						row.push(d.text);

						columnProps?.forEach((c: any) => {
							row.push(
								formatValue({
									value: s[c.source],
									unit: c.unit,
								})
							);
						});

						row.push('0%');

						context.push(row);
					});
				});

				return context;
			}
			case analysisTypes['Linear Regression']: {
				return;
			}
			default:
				return;
		}
	}
}

export const generateAiInsightsInExplore = createAsyncThunk<any, void, AsyncThunkConfig>(
	'aiInsights/generate-in-explore',
	async (_, { getState, dispatch }) => {
		const { enterpriseId } = getState().account;
		const { uid } = getState().auth.userInfo;
		const chart = getState().explore.chart;
		const { analysisDisplayName, employeeAttribute, mainSegments, breakdownSegment, interval } = getState().explore
			.filter as any;
		const subtableChart = getState().explore.subtable;

		if (!chart) {
			throw new Error('No explore data generated.');
		}

		const isSubtable = (breakdownSegment && breakdownSegment.values.length > 1) || employeeAttribute;

		const title = `${analysisDisplayName} by (${mainSegments?.map((s: any) => s.name).join(', ')})`;

		const aiContext = getAiInsightsContext({
			filter: getState().explore.filter,
			chart: getState().explore.chart,
			subtable: getState().explore.subtable,
			isSubtable,
		});

		console.log(aiContext);

		const conversation_id = uuid.v4();
		dispatch(setConversationId(conversation_id));

		const response: any = await axios.post(`${Urls.AiInsightsApi}insights/invoke`, {
			input: {
				model: 'gemini_pro_15',
				question: '',
				context: [
					{
						title,
						interval,
						analysis_type: isSubtable ? subtableChart.analysisType : chart.analysisType,
						data: JSON.stringify(aiContext),
						user_context: '',
					},
				],
			},
			config: {
				configurable: {
					conversation_id,
					enterprise_id: enterpriseId,
					user_id: uid,
				},
			},
		});

		return {
			insights: response?.data?.output,
			// insights: '',
		};
	}
);
